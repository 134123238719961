import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Hero from '../common/Hero';
import Delete from '../common/Delete';
import EditAndDeleteButtons from '../common/EditAndDeleteButtons';

import { getCourses, deleteCourse } from '../../actions/coursesActions';
import isEmpty from '../../validation/is-empty';

class AllCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      deleteModalState: false,
      id: null,
      errors: {},
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  componentDidMount() {
    this.props.getCourses();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors)
      this.setState({
        errors: nextProps.errors,
        courses: nextProps.courses.courses,
      });
    else this.setState({ courses: nextProps.courses.courses });
  }

  toggleDeleteModal(id) {
    this.setState((prevState) => {
      return { deleteModalState: !prevState.deleteModalState, id };
    });
  }

  doDelete(id) {
    this.props.deleteCourse(id, this.props.history);
    this.props.getCourses();
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { courses, fetching } = this.props.courses;

    const foot = (
      <div>
        {isAuthenticated && (
          <Link to="/cursos/crear">
            <button className="button is-success">Agregar Curso</button>
          </Link>
        )}
      </div>
    );

    return (
      <>
        <Hero title="Cursos" color="is-link" foot={foot} />
        <section className="container">
          {fetching && (
            <button className="button is-loading is-fullwidth is-medium" />
          )}
          {!fetching && (
            <div>
              {isEmpty(courses) && <p className="subtitle">No hay cursos</p>}
              {!isEmpty(courses) && (
                <>
                  {courses.map((value, index) => {
                    return (
                      <article
                        key={index}
                        className=""
                        style={{ marginBottom: '40px' }}
                      >
                        <div>
                          <section>
                            <article
                              className=""
                              style={{
                                marginBottom: '15px',
                                textAlign: 'left',
                              }}
                            >
                              <div
                                className={classnames('', {
                                  columns: isAuthenticated,
                                })}
                              >
                                <div
                                  className={classnames('', {
                                    'column is-5': isAuthenticated,
                                  })}
                                >
                                  <Link to={`/cursos/${courses[index]._id}`}>
                                    <p className="title">{value.name}</p>
                                    <p className="subtitle">
                                      {value.description}
                                    </p>
                                  </Link>
                                </div>
                                {isAuthenticated && (
                                  <div
                                    className="field is-grouped column is-2"
                                    style={{ justifyContent: 'flex-end' }}
                                  >
                                    <EditAndDeleteButtons
                                      toEdit={`/cursos/editar/${courses[index]._id}`}
                                      onDelete={() => {
                                        this.toggleDeleteModal(
                                          courses[index]._id,
                                        );
                                      }}
                                    />
                                    <Delete
                                      closeModal={this.toggleDeleteModal}
                                      modalState={this.state.deleteModalState}
                                      doDelete={this.doDelete}
                                      id={this.state.id}
                                      deleteWhat={'el curso'}
                                    />
                                  </div>
                                )}
                              </div>
                            </article>
                          </section>
                        </div>
                      </article>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </section>
      </>
    );
  }
}

AllCourses.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  courses: PropTypes.object.isRequired,
  getCourses: PropTypes.func.isRequired,
  deleteCourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  courses: state.courses,
});

export default connect(mapStateToProps, { getCourses, deleteCourse })(
  AllCourses,
);
