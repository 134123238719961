import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const EditAndDeleteButtons = ({ toEdit, onDelete }) => {
  return (
    <div className="field is-grouped" style={{ justifyContent: 'flex-end' }}>
      <p className="control">
        <Link to={toEdit}>
          <button className="button is-warning">Editar</button>
        </Link>
      </p>
      <p className="control">
        <span className="button is-danger" onClick={onDelete}>
          Eliminar
        </span>
      </p>
    </div>
  );
};

EditAndDeleteButtons.propTypes = {
  toEdit: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default EditAndDeleteButtons;
