import axios from 'axios';
import {
  FETCHING_LINKS,
  GET_LINKS,
  GET_LINK,
  GET_ERRORS,
  CREATE_LINK,
  DELETE_LINK,
  UPDATE_LINK,
} from './types';
import { checkIfSessionExpired } from './authActions';

const apiURL = process.env.REACT_APP_API_URL || '';

export const getLinks = () => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .get(`${apiURL}/api/links`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      crossdomain: true,
    })
    .then((links) => dispatch({ type: GET_LINKS, payload: links.data }))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getLink = (id) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setLinksLoading());
  axios
    .get(`${apiURL}/api/links/${id}`)
    .then((link) => dispatch({ type: GET_LINK, payload: link.data }))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const createLink = (linkData, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/links`, linkData)
    .then((link) => {
      history.push('/enlaces');
      dispatch({ type: CREATE_LINK, payload: link.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const updateLink = (id, linkData, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/links/${id}`, linkData)
    .then((link) => {
      history.push('/enlaces');
      dispatch({ type: UPDATE_LINK, payload: link.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const deleteLink = (id, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .delete(`${apiURL}/api/links/${id}`)
    .then((link) => {
      history.push('/enlaces');
      dispatch({ type: DELETE_LINK });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const setLinksLoading = () => {
  return { type: FETCHING_LINKS };
};
