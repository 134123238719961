import {
  FETCHING_POSTS,
  FETCHING_POST,
  GET_POSTS,
  GET_POST,
  UPDATE_POST,
  CREATE_POST,
  DELETE_POST,
  FETCHING_POST_RESOURCES,
  GET_POST_RESOURCES,
} from '../actions/types';

const initialState = {
  posts: null,
  post: null,
  fetching: false,
  resources: null,
  fetchingResources: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_POSTS:
      return { ...state, fetching: true };
    case FETCHING_POST:
      return { ...state, fetching: true };
    case GET_POSTS:
      return { ...state, fetching: false, posts: action.payload };
    case GET_POST:
      return { ...state, fetching: false, post: action.payload };
    case UPDATE_POST:
      return { ...state, post: action.payload };
    case CREATE_POST:
      return { ...state, post: action.payload };
    case DELETE_POST:
      return { ...state };
    case FETCHING_POST_RESOURCES:
      return { ...state, fetchingResources: true, resources: null };
    case GET_POST_RESOURCES:
      return { ...state, resources: action.payload, fetchingResources: false };
    default:
      return state;
  }
}
