import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Hero from '../common/Hero';
import Delete from '../common/Delete';

import {
  getResources,
  addFile,
  getPost,
  deleteResource,
  getResource,
} from '../../actions/postsActions';
import isEmpty from '../../validation/is-empty';

class Resources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: {},
      resources: [],
      deleteModalState: false,
      file: '',
      resourceId: null,
    };

    this.fileChanged = this.fileChanged.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.props.getPost(this.props.match.params.id);
    this.props.getResources(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const { post, resources } = nextProps.posts;
    this.setState({ post, resources, file: 'url' });
  }

  fileChanged(e) {
    const f = e.target.files[0];
    this.setState({
      file: f,
    });
  }

  uploadFile(e) {
    e.preventDefault();
    let fileData = new FormData();
    fileData.append('file', this.state.file);

    this.props.addFile(this.props.posts.post._id, fileData);
  }

  toggleDeleteModal(resourceId) {
    this.setState((prevState) => {
      return { deleteModalState: !prevState.deleteModalState, resourceId };
    });
  }

  doDelete(id) {
    this.props.deleteResource(this.props.match.params.id, id);
  }

  onClick(resourceId, download) {
    this.props.getResource(this.props.match.params.id, resourceId, download);
  }

  render() {
    const { fetchingResources, post, resources } = this.props.posts;
    if (post === null || fetchingResources) {
      return (
        <>
          <Hero subtitle="Recursos" color="is-link" />
          <section className="container">
            {fetchingResources && (
              <button className="button is-loading is-fullwidth is-medium" />
            )}
          </section>
        </>
      );
    } else {
      const { title } = post;
      const { isAuthenticated } = this.props.auth;

      const foot = (
        <>
          {isAuthenticated && (
            <section className="container">
              <input type="file" onChange={this.fileChanged} />
              <button onClick={this.uploadFile}>Guardar archivo</button>
            </section>
          )}
        </>
      );

      return (
        <>
          <Hero title={title} subtitle="Recursos" color="is-link" foot={foot} />
          <section className="container">
            {isEmpty(resources) && <p className="subtitle">No hay recursos</p>}
            {!isEmpty(resources) &&
              resources.map((value, index) => {
                return (
                  <div key={index} className="box">
                    <div className="columns">
                      <div
                        className={
                          isAuthenticated ? 'column is-9' : 'column is-11'
                        }
                      >
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          className="title"
                        >
                          {value.filename}
                        </p>
                      </div>
                      <div
                        className={
                          isAuthenticated
                            ? 'field is-grouped column is-3'
                            : 'field is-grouped column is-1'
                        }
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <div className="control">
                          <button
                            className="button is-success"
                            onClick={() => this.onClick(value._id, true)}
                          >
                            <span className="icon">
                              <i className="fas fa-file-download" />
                            </span>
                          </button>
                        </div>
                        <div className="control">
                          <button
                            className="button is-light"
                            onClick={() => this.onClick(value._id, false)}
                          >
                            <span className="icon">
                              <i className="fas fa-eye" />
                            </span>
                          </button>
                        </div>
                        {isAuthenticated && (
                          <div>
                            <div className="control">
                              <span
                                className="button is-danger"
                                onClick={() => {
                                  this.toggleDeleteModal(value._id);
                                }}
                              >
                                Eliminar
                              </span>
                              <Delete
                                closeModal={this.toggleDeleteModal}
                                modalState={this.state.deleteModalState}
                                doDelete={this.doDelete}
                                id={this.state.resourceId}
                                deleteWhat={'el recurso'}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </section>
        </>
      );
    }
  }
}

Resources.propTypes = {
  auth: PropTypes.object.isRequired,
  posts: PropTypes.object.isRequired,
  getResources: PropTypes.func.isRequired,
  deleteResource: PropTypes.func.isRequired,
  getResource: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  posts: state.posts,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getResources,
  addFile,
  getPost,
  deleteResource,
  getResource,
})(Resources);
