import {
  FETCHING_PUBLICATIONS,
  FETCHING_PUBLICATION,
  GET_PUBLICATIONS,
  GET_PUBLICATION,
  UPDATE_PUBLICATION,
  CREATE_PUBLICATION,
  DELETE_PUBLICATION,
  GET_PUBLICATIONS_RESOURCES,
  GET_PUBLICATIONS_RESOURCE,
  FETCHING_PUBLICATIONS_RESOURCES,
} from '../actions/types';

const initialState = {
  publications: { articles: [], conferences: [], processess: [] },
  publication: null,
  resources: null,
  resource: null,
  fetching: null,
  fetchingResources: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_PUBLICATIONS:
      return { ...state, fetching: true };
    case FETCHING_PUBLICATION:
      return { ...state, fetching: true };
    case GET_PUBLICATIONS:
      return { ...state, publications: action.payload, fetching: false };
    case GET_PUBLICATION:
      return { ...state, publication: action.payload, fetching: false };
    case UPDATE_PUBLICATION:
      return { ...state, publication: action.payload };
    case CREATE_PUBLICATION:
      return { ...state, publication: action.payload };
    case DELETE_PUBLICATION:
      return { ...state };
    case FETCHING_PUBLICATIONS_RESOURCES:
      return { ...state, fetchingResources: true, resources: null };
    case GET_PUBLICATIONS_RESOURCES:
      return { ...state, resources: action.payload, fetchingResources: false };
    case GET_PUBLICATIONS_RESOURCE:
      return { ...state, resource: action.payload };
    default:
      return state;
  }
}
