export const uploadImageCallBack = (file) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api.imgur.com/3/image');
    xhr.setRequestHeader(
      'Authorization',
      `Client-ID ${process.env.REACT_APP_IMGUR_CLIENT_ID}`,
    );
    const data = new FormData();
    data.append('image', file);
    xhr.send(data);
    xhr.addEventListener('load', () => {
      const response = JSON.parse(xhr.responseText);
      resolve(response);
    });
    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  });

export const socialMedia = [
  {
    name: 'Facebook',
    baseUrl: 'https://www.facebook.com/',
    user: '',
    iconClassName: 'fab fa-facebook-square',
  },
  {
    name: 'Twitter',
    baseUrl: 'https://www.twitter.com/',
    user: '',
    iconClassName: 'fab fa-twitter-square',
  },
  {
    name: 'LinkedIn',
    baseUrl: 'https://www.linkedin.com/in/',
    user: '',
    iconClassName: 'fab fa-linkedin',
  },
  {
    name: 'Instagram',
    baseUrl: 'https://www.instagram.com/',
    user: '',
    iconClassName: 'fab fa-instagram',
  },
  {
    name: 'Correo Electrónico',
    baseUrl: 'mailto:',
    user: '',
    iconClassName: 'far fa-envelope',
  },
  {
    name: 'Xing',
    baseUrl: 'https://www.xing.com/profile/',
    user: '',
    iconClassName: 'fab fa-xing-square',
  },
  {
    name: 'Buy Me A Coffee',
    baseUrl: 'https://www.buymeacoffee.com/',
    user: '',
    iconClassName: 'fas fa-coffee',
  },
  {
    name: 'GitHub',
    baseUrl: 'https://www.github.com/',
    user: '',
    iconClassName: 'fab fa-github',
  },
];

export const createFileAnchor = (download, fileName, url) => {
  const link = document.createElement('a');
  link.href = url;
  if (download) link.setAttribute('download', fileName);
  else link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
};
