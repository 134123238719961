import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { Provider } from 'react-redux';

import './App.css';
import store from './store';

import { setCurrentUser, logoutUser } from './actions/authActions';

import Header from './components/layout/Header';

// Import Components
import Home from './components/Home';
import Courses from './components/Courses/Courses';
import Publications from './components/Publications/Publications';
import Posts from './components/Posts/Posts';
import Links from './components/Links/Links';
import About from './components/About/About';
import NotFound from './components/NotFound';
import GA from './components/GoogleAnalytics';

require('default-passive-events');

class DebugRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    if (this.props.isHidden) return;
    console.log('initial history is: ', JSON.stringify(this.history, null, 2));
    this.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`,
      );
      console.log(
        `The last navigation action was ${action}`,
        JSON.stringify(this.history, null, 2),
      );
    });
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <div style={style}>
              <Header />
              <DebugRouter isHidden={true} />
              {GA.init() && <GA.RouteTracker />}
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/acerca-de" component={About} />
                <Route path="/cursos" component={Courses} />
                <Route path="/posts" component={Posts} />
                <Route path="/publicaciones" component={Publications} />
                <Route path="/enlaces" component={Links} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user in isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = '/';
  }
}

const style = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export default App;
