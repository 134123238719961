import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Dropdown } from 'react-bulma-components';

const SocialMediaDropdown = ({ options, onChange, socialNetwork }) => {
  const [selected, setSelected] = useState(socialNetwork.name || '');
  const [user, setUser] = useState(socialNetwork.user || '');
  const updateData = (type, value) => {
    if (type === 'Dropdown') {
      setSelected(value);
      onChange({
        ...options.find((option) => option.name === value),
        user,
      });
    } else {
      setUser(value);
      onChange({
        ...options.find((option) => option.name === selected),
        user: value,
      });
    }
  };
  return (
    <div className="field has-addons">
      <div className="control">
        <Dropdown
          value={selected}
          onChange={(value) => updateData('Dropdown', value)}
        >
          {options &&
            options.map((sm) => (
              <Dropdown.Item key={sm.name} value={sm.name}>
                {sm.name}
              </Dropdown.Item>
            ))}
        </Dropdown>
      </div>
      <div className="control">
        <input
          className="input"
          type="text"
          placeholder="Usuario"
          value={user}
          onChange={(e) => updateData('Input', e.target.value)}
        />
      </div>
    </div>
  );
};
SocialMediaDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  socialNetwork: PropTypes.shape({
    name: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }),
};

export default SocialMediaDropdown;
