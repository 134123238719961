import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { uploadImageCallBack } from '../../utils/utils';

const AboutAdmin = ({
  handleSubmit,
  content,
  onEditorStateChange,
  pageDescription,
  secondaryPageDescription,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <p className="subtitle">Descripción del Acerca de</p>
          <div className="control">
            <Editor
              editorState={content}
              wrapperClassName="demo-wrapper"
              editorClassName="box"
              toolbarClassName=""
              onEditorStateChange={(editorState) =>
                onEditorStateChange('content', editorState)
              }
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  alt: { present: true, mandatory: false },
                },
              }}
            />
          </div>
        </div>
        <div className="field">
          <p className="subtitle">Descripción para página inicial</p>
          <div className="control">
            <Editor
              editorState={pageDescription}
              wrapperClassName="demo-wrapper"
              editorClassName="box"
              toolbarClassName=""
              onEditorStateChange={(editorState) =>
                onEditorStateChange('pageDescription', editorState)
              }
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  alt: { present: true, mandatory: false },
                },
              }}
            />
          </div>
        </div>
        <div className="field">
          <p className="subtitle">Descripción secundaria para página inicial</p>
          <div className="control">
            <Editor
              editorState={secondaryPageDescription}
              wrapperClassName="demo-wrapper"
              editorClassName="box"
              toolbarClassName=""
              onEditorStateChange={(editorState) =>
                onEditorStateChange('secondaryPageDescription', editorState)
              }
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  alt: { present: true, mandatory: false },
                },
              }}
            />
          </div>
        </div>
        <div className="field">
          <div className="control buttons is-centered">
            <input
              className="button is-medium is-success"
              type="submit"
              value="Guardar"
            />
          </div>
        </div>
      </form>
    </>
  );
};
AboutAdmin.propTypes = {
  content: PropTypes.object.isRequired,
  pageDescription: PropTypes.object.isRequired,
  secondaryPageDescription: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onEditorStateChange: PropTypes.func.isRequired,
};

export default AboutAdmin;
