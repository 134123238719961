import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SocialMediaDropdown from './SocialMediaDropdown';
import { socialMedia } from '../../utils/utils';

const SocialMedia = (props) => {
  const [id, setId] = useState(props.socialNetworks.length + 1);
  const [socialNetworks, setSocialNetworks] = useState(props.socialNetworks);
  return (
    <div style={{ textAlign: 'left' }}>
      <p className="title">Redes Sociales</p>
      <button
        className="button"
        onClick={() => {
          setSocialNetworks([...socialNetworks, { ...socialMedia[0], id }]);
          setId(id + 1);
        }}
      >
        Agregar una red social
        <span className="icon is-large" style={{ paddingLeft: '10px' }}>
          <i className="fas fa-plus"></i>
        </span>
      </button>
      <br />
      <br />
      {socialNetworks.map((socialNetwork, index, array) => (
        <React.Fragment key={index}>
          <SocialMediaDropdown
            key={socialNetwork.id}
            options={socialMedia}
            socialNetwork={socialNetwork}
            onChange={(e) => {
              array.splice(index, 1, e);
              setSocialNetworks(array);
            }}
          />
        </React.Fragment>
      ))}
      <div className="field">
        <div className="control buttons">
          <button
            className="button is-small is-info"
            onClick={(e) => {
              e.preventDefault();
              props.saveSocialNetworks(socialNetworks);
            }}
          >
            Guardar Redes Sociales
          </button>
        </div>
      </div>
    </div>
  );
};
SocialMedia.propTypes = {
  socialNetworks: PropTypes.array.isRequired,
  saveSocialNetworks: PropTypes.func.isRequired,
};

export default SocialMedia;
