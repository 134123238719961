import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Hero from '../common/Hero';

import { getCourse } from '../../actions/coursesActions';

class Course extends Component {
  constructor(props) {
    super(props);

    this.state = {
      course: {},
    };
  }

  componentDidMount() {
    this.props.getCourse(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ course: nextProps.courses.course });
  }

  render() {
    const { fetching, course } = this.props.courses;

    if (course === null || fetching) {
      return (
        <>
          <Hero color="is-link" />
          <section className="container">
            {fetching && (
              <button className="button is-loading is-fullwidth is-medium" />
            )}
          </section>
        </>
      );
    } else {
      const { _id: id, name, description, objectives, resourcesSize } = course;

      return (
        <>
          <Hero title={name} color="is-link" />
          <section className="container" style={{ textAlign: 'left' }}>
            <article className="box">
              <p className="title">Descripción</p>
              <p className="subtitle">{description}</p>
            </article>
            <article className="box">
              <p className="title">Objetivos</p>
              <aside className="content">
                <ul className="">
                  {objectives.map((value, index) => {
                    return (
                      <li key={index}>
                        <p className="subtitle">{value}</p>
                      </li>
                    );
                  })}
                </ul>
              </aside>
            </article>
            {(resourcesSize > 0 || this.props.auth.isAuthenticated) && (
              <Link to={`/cursos/${id}/recursos`}>
                <article className="box">
                  <p className="title">Recursos</p>
                </article>
              </Link>
            )}
          </section>
        </>
      );
    }
  }
}

Course.propTypes = {
  courses: PropTypes.object.isRequired,
  getCourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  courses: state.courses,
  auth: state.auth,
});

export default connect(mapStateToProps, { getCourse })(Course);
