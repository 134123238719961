import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AllLinks from './AllLinks';
import CreateOrEditLink from './CreateOrEditLink';
import NotFound from '../NotFound';

class Links extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/enlaces" component={AllLinks} />
          <Route
            exact
            path="/enlaces/crear"
            render={(props) => <CreateOrEditLink edit={false} />}
          />
          <Route
            exact
            path="/enlaces/editar/:id"
            render={(props) => <CreateOrEditLink edit={true} />}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default Links;
