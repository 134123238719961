import axios from 'axios';
import {
  FETCHING_PUBLICATIONS,
  FETCHING_PUBLICATION,
  GET_ERRORS,
  GET_PUBLICATIONS,
  GET_PUBLICATION,
  CREATE_PUBLICATION,
  UPDATE_PUBLICATION,
  DELETE_PUBLICATION,
  DELETE_RESOURCE,
  FETCHING_PUBLICATIONS_RESOURCES,
  GET_PUBLICATIONS_RESOURCES,
  GET_PUBLICATIONS_RESOURCE,
} from './types';
import { checkIfSessionExpired } from './authActions';
import { createFileAnchor } from '../utils/utils';

const apiURL = process.env.REACT_APP_API_URL || '';

export const getPublications = () => async (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setPublicationsLoading());
  const publications = { articles: [], conferences: [], processes: [] };
  const URI = `${apiURL}/api/publications`;
  try {
    let response = await axios.get(`${URI}?list=true&type=Artículo`);
    publications.articles = response.data;
    response = await axios.get(`${URI}?list=true&type=Conferencia`);
    publications.conferences = response.data;
    response = await axios.get(`${URI}?list=true&type=Otra%20Publicación`);
    publications.processes = response.data;

    dispatch({ type: GET_PUBLICATIONS, payload: publications });
  } catch (err) {
    if (err.response) {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    } else {
      console.log(err);
    }
  }
};

export const getPublication = (id) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setPublicationLoading());
  axios
    .get(`${apiURL}/api/publications/${id}`)
    .then((publication) =>
      dispatch({ type: GET_PUBLICATION, payload: publication.data }),
    )
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const createPublication = (publicationData, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/publications`, publicationData)
    .then((publication) => {
      history.push(`/publicaciones/${publication.data._id}`);
      dispatch({ type: CREATE_PUBLICATION, payload: publication.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const updatePublication = (id, publicationData, history) => (
  dispatch,
) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/publications/${id}`, publicationData)
    .then((publication) => {
      history.push(`/publicaciones/${publication.data._id}`);
      dispatch({ type: UPDATE_PUBLICATION, payload: publication.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const deletePublication = (id, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .delete(`${apiURL}/api/publications/${id}`)
    .then((publication) => {
      history.push(`/publicaciones`);
      dispatch({ type: DELETE_PUBLICATION });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const addFile = (id, fileData) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/publications/${id}/resources`, fileData)
    .then((res) => {
      dispatch(getResources(id));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getResources = (id) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setResourcesLoading());
  axios
    .get(`${apiURL}/api/publications/${id}/resources`)
    .then((resources) => {
      dispatch({ type: GET_PUBLICATIONS_RESOURCES, payload: resources.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const deleteResource = (publicationId, resourceId) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .delete(
      `${apiURL}/api/publications/${publicationId}/resources/${resourceId}`,
    )
    .then((resource) => {
      dispatch({ type: DELETE_RESOURCE });
      dispatch(getResources(publicationId));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getResource = (publicationId, resourceId, download) => (
  dispatch,
) => {
  dispatch(checkIfSessionExpired());
  axios({
    url: `${apiURL}/api/publications/${publicationId}/resources/${resourceId}`,
    method: 'GET',
  })
    .then(({ data: resource }) => {
      const blob = new Blob([new Uint8Array(resource.data.data)], {
        type: resource.contentType,
      });
      createFileAnchor(
        download,
        resource.filename,
        download
          ? URL.createObjectURL(blob)
          : `${apiURL}/${encodeURI(resource.path)}`,
      );
      dispatch({ type: GET_PUBLICATIONS_RESOURCE, payload: blob });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const setPublicationsLoading = () => {
  return { type: FETCHING_PUBLICATIONS };
};

export const setPublicationLoading = () => {
  return { type: FETCHING_PUBLICATION };
};

export const setResourcesLoading = () => {
  return { type: FETCHING_PUBLICATIONS_RESOURCES };
};
