import React, { Component } from 'react';

class Delete extends Component {
  constructor(props) {
    super(props);

    this.delete = this.delete.bind(this);
  }

  delete() {
    this.props.doDelete(this.props.id);
    this.props.closeModal();
  }

  render() {
    const { closeModal, modalState, deleteWhat } = this.props;
    if (!modalState) return null;
    return (
      <div className="modal is-active is-warning">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title" />
            <button className="delete" onClick={closeModal} />
          </header>
          <section className="modal-card-body has-text-centered">
            <p className="modal-card-title">{`Seguro desea eliminar ${deleteWhat}`}</p>
            <br />
            <div className="field is-grouped is-grouped-centered">
              <p className="control">
                <button className="button is-light" onClick={closeModal}>
                  Cancelar
                </button>
              </p>
              <p className="control">
                <button className="button is-danger" onClick={this.delete}>
                  Eliminar
                </button>
              </p>
            </div>
          </section>
        </div>
        <button className="modal-close is-large" aria-label="close" />
      </div>
    );
  }
}

export default Delete;
