import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const CreateField = ({
  htmlFor,
  label,
  name,
  placeholder,
  value,
  onChange,
  error,
  info,
  type,
  disabled,
}) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label" htmlFor={htmlFor}>
          {label}
        </label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              className={classnames('input', { 'input is-danger': error })}
              name={name}
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />
            {error && <p className="help is-danger">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

CreateField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
};

CreateField.defaultProps = {
  type: 'text',
};

export default CreateField;
