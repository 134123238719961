import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const LoginField = ({
  name,
  placeholder,
  value,
  onChange,
  label,
  error,
  info,
  type,
  disabled,
  htmlFor,
}) => {
  return (
    <div className="field">
      <label className="label" htmlFor={htmlFor}>
        {label}
      </label>
      <div className="control">
        <input
          className={classnames('input', { 'input is-danger': error })}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {error && <p className="help is-danger">{error}</p>}
      </div>
    </div>
  );
};

LoginField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  info: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
};

LoginField.defaultProps = {
  type: 'text',
};

export default LoginField;
