import axios from 'axios';
import jwt_decode from 'jwt-decode';

import setAuthToken from '../utils/setAuthToken';

import { GET_ERRORS, SET_CURRENT_USER, NO_ERRORS } from './types';

const apiURL = process.env.REACT_APP_API_URL || '';

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(`${apiURL}/api/register`, userData)
    .then((res) => history.push('login'))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

// Login - Get User Token
export const loginUser = (userData, success) => (dispatch) => {
  axios
    .post(`${apiURL}/api/login`, userData)
    .then((res) => {
      // Save to local storage
      const { token } = res.data;
      // Set token to local storage
      localStorage.setItem('jwtToken', token);
      // Set toke to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      //Set current user
      dispatch({ type: NO_ERRORS });
      if (success) success();
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const checkIfSessionExpired = () => (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  try {
    const { exp } = jwt_decode(token);
    if (Date.now() / 1000 > exp) {
      dispatch(setCurrentUser({}));
    }
  } catch (err) {
    dispatch(setCurrentUser({}));
  }
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object
  dispatch(setCurrentUser({}));
};
