import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Hero from '../common/Hero';
import CreateField from '../common/CreateField';

import { getLink, createLink, updateLink } from '../../actions/linksActions';

class CreateOrEditLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      link: '',
      id: null,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) this.props.history.push('/enlaces');
    if (this.props.edit) this.props.getLink(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.auth.isAuthenticated) this.props.history.push('/enlaces');

    let errors = {};
    if (nextProps.errors) errors = nextProps.errors;

    if (this.props.edit && nextProps.links.link) {
      const { _id: id, link, name } = nextProps.links.link;
      this.setState({ errors, link, name, id });
    } else {
      this.setState({ errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { id, link, name } = this.state;
    const linkData = { link, name };
    if (this.props.edit) {
      this.props.updateLink(id, linkData, this.props.history);
    } else this.props.createLink(linkData, this.props.history);
  }

  render() {
    const { link, name } = this.state;
    return (
      <div>
        <Hero
          title={this.props.edit ? 'Editar Enlace' : 'Crear Enlace'}
          color="is-link"
        />
        <div className="container has-text-centered box">
          <form onSubmit={this.onSubmit}>
            <CreateField
              htmlFor="name"
              label="Nombre"
              name="name"
              placeholder="Nombre"
              value={name}
              onChange={this.onChange}
              error={this.state.errors.name}
            />
            <CreateField
              htmlFor="link"
              label="Enlace"
              name="link"
              placeholder="Enlace"
              value={link}
              onChange={this.onChange}
              error={this.state.errors.link}
            />
            <div className="field">
              <div className="control buttons is-centered">
                <input
                  className="button is-success is-fullwidth"
                  type="submit"
                  value={this.props.edit ? 'Editar' : 'Crear'}
                  style={{ maxWidth: '100px' }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CreateOrEditLink.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  links: PropTypes.object,
  getLink: PropTypes.func.isRequired,
  createLink: PropTypes.func.isRequired,
  updateLink: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  links: state.links,
});

export default connect(mapStateToProps, { getLink, createLink, updateLink })(
  withRouter(CreateOrEditLink),
);
