export const GET_ERRORS = 'GET_ERRORS';
export const NO_ERRORS = 'NO_ERRORS';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Courses
export const GET_COURSES = 'GET_COURSES';
export const FETCHING_COURSES = 'FETCHING_COURSES';
export const GET_COURSE = 'GET_COURSE';
export const FETCHING_COURSE = 'FETCHING_COURSE';
export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_RESOURCE = 'GET_RESOURCE';
export const FETCHING_RESOURCES = 'FETCHING_RESOURCES';
export const CREATE_COURSE = 'CREATE_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';

// Publication
export const GET_PUBLICATIONS = 'GET_PUBLICATIONS';
export const FETCHING_PUBLICATIONS = 'FETCHING_PUBLICATIONS';
export const FETCHING_PUBLICATIONS_RESOURCES =
  'FETCHING_PUBLICATIONS_RESOURCES';
export const GET_PUBLICATIONS_RESOURCES = 'GET_PUBLICATIONS_RESOURCES';
export const GET_PUBLICATIONS_RESOURCE = 'GET_PUBLICATIONS_RESOURCE';
export const GET_PUBLICATION = 'GET_PUBLICATION';
export const FETCHING_PUBLICATION = 'FETCHING_PUBLICATION';
export const CREATE_PUBLICATION = 'CREATE_PUBLICATION';
export const UPDATE_PUBLICATION = 'UPDATE_PUBLICATION';
export const DELETE_PUBLICATION = 'DELETE_PUBLICATION';

// Posts
export const GET_POSTS = 'GET_POSTS';
export const FETCHING_POSTS = 'FETCHING_POSTS';
export const GET_POST = 'GET_POST';
export const GET_POST_RESOURCES = 'GET_POST_RESOURCES';
export const GET_POST_RESOURCE = 'GET_POST_RESOURCE';
export const FETCHING_POST_RESOURCES = 'FETCHING_POST_RESOURCES';
export const FETCHING_POST = 'FETCHING_POST';
export const CREATE_POST = 'CREATE_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_RESOURCE = 'DELETE_POST_RESOURCE';

// Links
export const GET_LINKS = 'GET_LINKS';
export const GET_LINK = 'GET_LINK';
export const FETCHING_LINKS = 'FETCHING_LINKS';
export const CREATE_LINK = 'CREATE_LINK';
export const UPDATE_LINK = 'UPDATE_LINK';
export const DELETE_LINK = 'DELETE_LINK';

// About
export const GET_ABOUT = 'GET_ABOUT';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const FETCHING_ABOUT = 'FETCHING_ABOUT';
export const IN_ABOUT = 'IN_ABOUT';
