import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoginField from '../common/LoginField';
import Modal from '../common/Modal';

import { loginUser } from '../../actions/authActions';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { email: '', password: '', errors: {} };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) this.setState({ errors: nextProps.errors });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const { email, password } = this.state;
    const userData = { email, password };
    this.props.loginUser(userData, this.props.closeModal);
  }

  render() {
    const { errors } = this.state;
    const { closeModal, modalState } = this.props;
    return (
      <Modal
        title="Entrar"
        close="Cerrar"
        closeModal={closeModal}
        modalState={modalState}
      >
        <div className="content">
          <div
            className="container has-text-centered box"
            style={{ maxWidth: '300px' }}
          >
            <form onSubmit={this.onSubmit}>
              <LoginField
                htmlFor="email"
                label="Correo Electrónico"
                name="email"
                type="email"
                placeholder="Correo Electrónico"
                onChange={this.onChange}
                error={errors.email}
              />
              <LoginField
                htmlFor="password"
                label="Contraseña"
                name="password"
                type="password"
                placeholder="Contraseña"
                onChange={this.onChange}
                error={errors.password}
              />

              <div className="field">
                <div className="control buttons is-centered">
                  <input
                    className="button is-medium is-success is-fullwidth"
                    type="submit"
                    value="Ingresar"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth, errors: state.errors });

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
