import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Hero from '../common/Hero';
import CreateField from '../common/CreateField';

import {
  getCourse,
  createCourse,
  updateCourse,
} from '../../actions/coursesActions';
import isEmpty from '../../validation/is-empty';

class CreateOrEditCourse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      objectives: [],
      objective: '',
      id: null,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addObjective = this.addObjective.bind(this);
    this.deleteObjective = this.deleteObjective.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) this.props.history.push('/cursos');
    if (this.props.edit) this.props.getCourse(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.auth.isAuthenticated) this.props.history.push('/cursos');

    let errors = {};
    if (nextProps.errors) errors = nextProps.errors;

    if (this.props.edit && nextProps.courses.course) {
      const {
        _id: id,
        name,
        description,
        objectives,
      } = nextProps.courses.course;
      this.setState({ errors, name, description, objectives, id });
    } else {
      this.setState({ errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { id, name, description, objectives } = this.state;
    const course = { name, description, objectives };
    if (this.props.edit) {
      this.props.updateCourse(id, course, this.props.history);
    } else this.props.createCourse(course, this.props.history);
  }

  addObjective() {
    const { objectives, objective } = this.state;
    if (isEmpty(objective)) return;
    objectives.push(objective);
    this.setState({ objectives, objective: '' });
  }

  deleteObjective(e) {
    e.preventDefault();
    const index = e.target.id;
    const { objectives } = this.state;
    objectives.splice(index, 1);
    this.setState({ objectives });
  }

  render() {
    const { name, description, objectives, objective } = this.state;
    return (
      <>
        <Hero
          title={this.props.edit ? 'Editar Curso' : 'Crear Curso'}
          color="is-link"
        />
        <div className="container has-text-centered box">
          <form onSubmit={this.onSubmit}>
            <CreateField
              htmlFor="name"
              label="Nombre"
              name="name"
              placeholder="Nombre"
              value={name}
              onChange={this.onChange}
              error={this.state.errors.name}
            />
            <CreateField
              htmlFor="description"
              label="Descripción"
              name="description"
              placeholder="Descripción"
              value={description}
              onChange={this.onChange}
              error={this.state.errors.description}
            />
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="objective">
                  Objetivo
                </label>
              </div>
              <div className="field-body">
                <div className="field is-grouped">
                  <div className="control has-icons-right is-expanded">
                    <input
                      className="input"
                      name="objective"
                      placeholder="Objetivo"
                      onChange={this.onChange}
                      value={objective}
                    />
                  </div>
                  <p className="control">
                    <span
                      className="button is-success"
                      onClick={this.addObjective}
                    >
                      +
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal" />
              <div className="field-body">
                <div className="field tags">
                  {objectives.map((value, index) => {
                    return (
                      <span className="tag is-medium" key={index}>
                        {value}
                        <button
                          className="delete is-small"
                          onClick={this.deleteObjective}
                          id={index}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="field">
              <div className="control buttons is-centered">
                <input
                  className="button is-success is-fullwidth"
                  type="submit"
                  value={this.props.edit ? 'Editar' : 'Crear'}
                  style={{ maxWidth: '100px' }}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

CreateOrEditCourse.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  courses: PropTypes.object,
  getCourse: PropTypes.func.isRequired,
  createCourse: PropTypes.func.isRequired,
  updateCourse: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  courses: state.courses,
});

export default connect(mapStateToProps, {
  getCourse,
  createCourse,
  updateCourse,
})(withRouter(CreateOrEditCourse));
