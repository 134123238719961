import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Hero from '../common/Hero';
import Delete from '../common/Delete';
import PublicationVisualization from './PublicationVisualization';

import {
  getPublications,
  deletePublication,
} from '../../actions/publicationsActions';

class AllPublications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      publications: { articles: [], conferences: [], processes: [] },
      deleteModalState: false,
      id: null,
      errors: {},
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  componentDidMount() {
    this.props.getPublications();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors)
      this.setState({
        errors: nextProps.errors,
        publications: nextProps.publications.publications,
      });
    else this.setState({ publications: this.props.publications.publications });
  }

  toggleDeleteModal(id) {
    this.setState((prevState) => {
      return { deleteModalState: !prevState.deleteModalState, id };
    });
  }

  doDelete(id) {
    this.props.deletePublication(id, this.props.history);
    this.props.getPublications();
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const {
      publications: { articles, conferences, processes },
      fetching,
    } = this.props.publications;

    const foot = (
      <>
        {isAuthenticated && (
          <Link to="/publicaciones/crear">
            <button className="button is-success">Agregar Publicación</button>
          </Link>
        )}
      </>
    );

    return (
      <div>
        <Hero title="Publicaciones" color="is-link" foot={foot} />
        <section className="container">
          {fetching && (
            <button className="button is-loading is-fullwidth is-medium" />
          )}
          {!fetching && (
            <div>
              <PublicationVisualization
                title="Artículos"
                data={articles}
                isAuthenticated={isAuthenticated}
                toggleDeleteModal={this.toggleDeleteModal}
                deleteComponent={
                  <Delete
                    closeModal={this.toggleDeleteModal}
                    modalState={this.state.deleteModalState}
                    doDelete={this.doDelete}
                    id={this.state.id}
                    deleteWhat={'la publicación'}
                  />
                }
              />
              <PublicationVisualization
                title="Conferencias"
                data={conferences}
                isAuthenticated={isAuthenticated}
                toggleDeleteModal={this.toggleDeleteModal}
                deleteComponent={
                  <Delete
                    closeModal={this.toggleDeleteModal}
                    modalState={this.state.deleteModalState}
                    doDelete={this.doDelete}
                    id={this.state.id}
                    deleteWhat={'la publicación'}
                  />
                }
              />
              <PublicationVisualization
                title="Otras Publicaciones"
                data={processes}
                isAuthenticated={isAuthenticated}
                toggleDeleteModal={this.toggleDeleteModal}
                deleteComponent={
                  <Delete
                    closeModal={this.toggleDeleteModal}
                    modalState={this.state.deleteModalState}
                    doDelete={this.doDelete}
                    id={this.state.id}
                    deleteWhat={'la publicación'}
                  />
                }
              />
            </div>
          )}
        </section>
      </div>
    );
  }
}

AllPublications.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  publications: PropTypes.object.isRequired,
  getPublications: PropTypes.func.isRequired,
  deletePublication: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  publications: state.publications,
});

export default connect(mapStateToProps, { getPublications, deletePublication })(
  AllPublications,
);
