import {
  GET_LINKS,
  FETCHING_LINKS,
  CREATE_LINK,
  DELETE_LINK,
  UPDATE_LINK,
  GET_LINK
} from "../actions/types";

const initialState = {
  links: null,
  link: null,
  fetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_LINKS:
      return { ...state, fetching: true };
    case GET_LINKS:
      return { ...state, links: action.payload, fetching: false };
    case GET_LINK:
      return { ...state, link: action.payload, fetching: false };
    case CREATE_LINK:
      return { ...state, link: action.payload };
    case UPDATE_LINK:
      return { ...state, link: action.payload };
    case DELETE_LINK:
      return { ...state };
    default:
      return state;
  }
}
