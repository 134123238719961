import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Hero from '../common/Hero';
import Delete from '../common/Delete';
import EditAndDeleteButtons from '../common/EditAndDeleteButtons';

import { getLinks, deleteLink } from '../../actions/linksActions';
import isEmpty from '../../validation/is-empty';

class AllLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
      deleteModalState: false,
      id: null,
      errors: {},
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  componentDidMount() {
    this.props.getLinks();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors)
      this.setState({ errors: nextProps.errors, links: nextProps.links.links });
    else this.setState({ links: nextProps.links.links });
  }

  toggleDeleteModal(id) {
    this.setState((prevState) => {
      return { deleteModalState: !prevState.deleteModalState, id };
    });
  }

  doDelete(id) {
    this.props.deleteLink(id, this.props.history);
    this.props.getLinks();
  }

  search(search) {
    const http = 'http://';
    if (search.includes(http) || search.includes('https://')) return search;
    return http.concat(search);
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { links, fetching } = this.props.links;

    const foot = (
      <div>
        {isAuthenticated && (
          <Link to="/enlaces/crear">
            <button className="button is-success">Agregar Enlace</button>
          </Link>
        )}
      </div>
    );

    return (
      <div>
        <Hero title="Enlaces" color="is-link" foot={foot} />
        <section className="container">
          {fetching && (
            <button className="button is-loading is-fullwidth is-medium" />
          )}
          {!fetching && (
            <div>
              {isEmpty(links) && <p className="subtitle">No hay enlaces</p>}
              {!isEmpty(links) && (
                <div className="">
                  {links.map((value, index) => {
                    return (
                      <section key={index}>
                        <article
                          className="box"
                          style={{ marginBottom: '15px', textAlign: 'left' }}
                        >
                          <div
                            className={classnames('', {
                              columns: isAuthenticated,
                            })}
                          >
                            <div
                              className={classnames('', {
                                'column is-10': isAuthenticated,
                              })}
                            >
                              <a
                                href={value.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p className="title">{value.name}</p>
                              </a>
                            </div>

                            {isAuthenticated && (
                              <div
                                className="field is-grouped column is-2"
                                style={{ justifyContent: 'flex-end' }}
                              >
                                <EditAndDeleteButtons
                                  toEdit={`/enlaces/editar/${links[index]._id}`}
                                  onDelete={() => {
                                    this.toggleDeleteModal(links[index]._id);
                                  }}
                                />
                                <Delete
                                  closeModal={this.toggleDeleteModal}
                                  modalState={this.state.deleteModalState}
                                  doDelete={this.doDelete}
                                  id={this.state.id}
                                  deleteWhat={'el enlace'}
                                />
                              </div>
                            )}
                          </div>
                        </article>
                      </section>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </section>
      </div>
    );
  }
}

AllLinks.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  getLinks: PropTypes.func.isRequired,
  deleteLink: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  links: state.links,
});

export default connect(mapStateToProps, { getLinks, deleteLink })(AllLinks);
