import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EditAndDeleteButtons from '../common/EditAndDeleteButtons';
import isEmpty from '../../validation/is-empty';

const PublicationVisualization = ({
  title,
  data,
  isAuthenticated,
  toggleDeleteModal,
  deleteComponent,
}) => {
  return (
    <article className="">
      <p className="title">{title}</p>
      {isEmpty(data) && <p className="subtitle">{`No hay ${title}`}</p>}
      {!isEmpty(data) && (
        <div>
          {data.map((value, index) => {
            return (
              <section key={index}>
                <article
                  className="box"
                  style={{ marginBottom: '15px', textAlign: 'left' }}
                >
                  <div className={classnames('', { columns: isAuthenticated })}>
                    <div
                      className={classnames('', {
                        'column is-10': isAuthenticated,
                      })}
                    >
                      <Link to={`/publicaciones/${value._id}`}>
                        <p className="title">{value.title}</p>
                        <p className="subtitle">{value.description}</p>
                      </Link>
                    </div>

                    {isAuthenticated && (
                      <div
                        className="field is-grouped column is-2"
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <EditAndDeleteButtons
                          toEdit={`/publicaciones/editar/${value._id}`}
                          onDelete={() => {
                            toggleDeleteModal(value._id);
                          }}
                        />
                        {deleteComponent}
                      </div>
                    )}
                  </div>
                </article>
              </section>
            );
          })}
        </div>
      )}
      <br />
    </article>
  );
};

PublicationVisualization.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  isAuthenticated: PropTypes.bool.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  deleteComponent: PropTypes.object.isRequired,
};

export default PublicationVisualization;
