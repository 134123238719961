import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import { isEmpty } from 'rambda';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Hero from '../common/Hero';
import Footer from '../layout/Footer';

import { getAbout, updateAbout, inAbout } from '../../actions/aboutActions';
import { uploadImageCallBack } from '../../utils/utils';
import AboutAdmin from './AboutAdmin';
import SocialMediaIcon from './SocialMediaIcon';
import SocialMedia from './SocialMedia';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      about: '',
      content: EditorState.createEmpty(),
      socialNetworks: [],
      pageDescription: EditorState.createEmpty(),
      secondaryPageDescription: EditorState.createEmpty(),
      errors: {},
      file: '',
      imageUrl: '',
      resolution: '',
    };
  }

  componentDidMount() {
    this.props.getAbout();
    this.props.inAbout(true);
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    this.props.inAbout(false);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () =>
    this.setState({
      resolution: window.innerWidth > 640 ? 'desktop' : 'mobile',
    });

  componentWillReceiveProps(nextProps) {
    const {
      about: {
        about: {
          description,
          imageUrl,
          socialNetworks,
          pageDescription,
          secondaryPageDescription,
        },
      },
      errors,
    } = nextProps;
    if (!isEmpty(errors)) {
      this.setState({
        errors: errors,
        about: description,
      });
    } else {
      this.setState({
        content: this.getEditorState(description),
        pageDescription: this.getEditorState(pageDescription),
        secondaryPageDescription: this.getEditorState(secondaryPageDescription),
        imageUrl,
        socialNetworks,
      });
    }
  }

  getEditorState = (content) => {
    if (content) {
      const contentBlock = htmlToDraft(content);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        return EditorState.createWithContent(contentState);
      }
    } else {
      return EditorState.createEmpty();
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onEditorStateChange = (type, editorState) => {
    this.setState({
      [type]: editorState,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.updateAboutHelper();
  };

  updateAboutHelper = async () => {
    let { content, pageDescription, secondaryPageDescription } = this.state;
    const { imageUrl, socialNetworks } = this.state;
    content = draftToHtml(convertToRaw(content.getCurrentContent()));
    pageDescription = draftToHtml(
      convertToRaw(pageDescription.getCurrentContent()),
    );
    secondaryPageDescription = draftToHtml(
      convertToRaw(secondaryPageDescription.getCurrentContent()),
    );
    await this.props.updateAbout({
      description: content,
      imageUrl,
      socialNetworks,
      pageDescription,
      secondaryPageDescription,
    });
    await this.props.getAbout();
  };

  saveSocialNetworks = async (socialNetworks) => {
    await this.setState({ socialNetworks });
    await this.updateAboutHelper();
  };

  fileChanged = (e) => {
    const f = e.target.files[0];
    this.setState({
      file: f,
    });
  };

  uploadFile = async (e) => {
    e.preventDefault();
    const response = await uploadImageCallBack(this.state.file);
    if (response && response.success) {
      this.setState({ imageUrl: response.data.link, file: '' });
      await this.props.updateAbout({
        description: this.state.about,
        imageUrl: this.state.imageUrl,
      });
    }
  };

  render() {
    const { description: about, imageUrl } = this.props.about.about;
    const { fetching } = this.props.about;
    const isAuthenticated = this.props.auth.isAuthenticated;

    const foot = (
      <>
        {isAuthenticated && (
          <section className="container">
            <input type="file" onChange={this.fileChanged} />
            <button onClick={this.uploadFile}>Cambiar imagen</button>
          </section>
        )}
      </>
    );

    return (
      <>
        <div style={{ flex: '1 0 auto' }}>
          <Hero title="Acerca de" color="is-link" foot={foot} />
          <section className="container">
            {fetching && (
              <button className="button is-loading is-fullwidth is-medium" />
            )}
            {!fetching && (
              <div className="box">
                <article
                  className={this.state.resolution === 'desktop' ? 'media' : ''}
                >
                  <div id="about-image-container" className="media-left">
                    <img
                      src={imageUrl}
                      alt=""
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                      }}
                    />
                  </div>
                  <div className="media-content">
                    {isAuthenticated && (
                      <AboutAdmin
                        content={this.state.content}
                        pageDescription={this.state.pageDescription}
                        secondaryPageDescription={
                          this.state.secondaryPageDescription
                        }
                        handleSubmit={this.handleSubmit}
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    )}
                    {!isAuthenticated && (
                      <>
                        <p className="subtitle">{renderHTML(about || '')}</p>
                        <div
                          className={
                            this.state.resolution === 'desktop'
                              ? ''
                              : 'has-text-centered'
                          }
                        >
                          {this.state.socialNetworks.map((socialNetwork) => (
                            <SocialMediaIcon
                              socialNetwork={socialNetwork}
                              key={socialNetwork.id}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </article>
                {isAuthenticated && (
                  <SocialMedia
                    socialNetworks={this.state.socialNetworks || []}
                    saveSocialNetworks={this.saveSocialNetworks}
                  />
                )}
              </div>
            )}
          </section>
        </div>
        <br />
        <Footer />
      </>
    );
  }
}

About.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  about: PropTypes.object.isRequired,
  getAbout: PropTypes.func.isRequired,
  updateAbout: PropTypes.func.isRequired,
  inAbout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  about: state.about,
  auth: state.auth,
});

export default connect(mapStateToProps, { getAbout, updateAbout, inAbout })(
  About,
);
