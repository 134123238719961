import React from 'react';

function NotFound() {
  return (
    <div className="container">
      <p className="title">Pagina no encontrada</p>
    </div>
  );
}
export default NotFound;
