import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import AllPublications from './AllPublications';
import Publication from './Publication';
import CreateOrEditPublication from './CreateOrEditPublication';
import NotFound from '../NotFound';
import Resources from './Resources';

class Publications extends Component {
  render() {
    return (
      <div>
        <div>
          <Switch>
            <Route exact path="/publicaciones" component={AllPublications} />
            <Route
              exact
              path="/publicaciones/crear"
              render={(props) => <CreateOrEditPublication edit={false} />}
            />
            <Route
              path="/publicaciones/editar/:id"
              render={(props) => <CreateOrEditPublication edit={true} />}
            />
            <Route
              exact
              path="/publicaciones/:id/recursos"
              component={Resources}
            />
            <Route path="/publicaciones/:id" component={Publication} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Publications;
