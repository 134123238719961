import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classnames from 'classnames';

import Hero from '../common/Hero';
import CreateField from '../common/CreateField';

import {
  getPublication,
  createPublication,
  updatePublication,
} from '../../actions/publicationsActions';
import { uploadImageCallBack } from '../../utils/utils';

class CreateOrEditPublication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      content: EditorState.createEmpty(),
      type: 'Artículo',
      id: null,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated)
      this.props.history.push('/publicaciones');
    if (this.props.edit) this.props.getPublication(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.auth.isAuthenticated)
      this.props.history.push('/publicaciones');

    let errors = {};
    if (nextProps.errors) errors = nextProps.errors;

    if (this.props.edit && nextProps.publications.publication) {
      const {
        _id: id,
        title,
        description,
        content,
        type,
      } = nextProps.publications.publication;

      let editorState;
      if (content) {
        const contentBlock = htmlToDraft(content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          editorState = EditorState.createWithContent(contentState);
        }
      } else {
        editorState = EditorState.createEmpty();
      }

      this.setState({
        errors,
        title,
        description,
        content: editorState,
        type,
        id,
      });
    } else {
      this.setState({ errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      content: editorState,
    });
  };

  onSubmit(e) {
    e.preventDefault();
    const { id, title, description, type } = this.state;
    let { content } = this.state;
    content = draftToHtml(convertToRaw(content.getCurrentContent()));
    const publication = { title, description, content, type };
    if (this.props.edit)
      this.props.updatePublication(id, publication, this.props.history);
    else this.props.createPublication(publication, this.props.history);
  }

  render() {
    const { title, description, content, type } = this.state;
    return (
      <div>
        <Hero
          title={this.props.edit ? 'Editar Publicación' : 'Crear Publicación'}
          color="is-link"
        />
        <div className="container has-text-centered box">
          <form onSubmit={this.onSubmit}>
            <CreateField
              htmlFor="title"
              label="Titulo"
              name="title"
              placeholder="Titulo"
              value={title}
              onChange={this.onChange}
              error={this.state.errors.title}
            />
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="type">
                  Tipo de Publicación
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <div className="select">
                      <select name="type" value={type} onChange={this.onChange}>
                        <option name="article">Artículo</option>
                        <option name="conference">Conferencia</option>
                        <option name="other">Otra Publicación</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="description">
                  Descripción
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <textarea
                      className={classnames('textarea', {
                        'textarea is-danger': this.state.errors.description,
                      })}
                      name="description"
                      placeholder="Descripción"
                      rows="4"
                      value={description}
                      onChange={this.onChange}
                    />
                  </div>
                  {this.state.errors.description && (
                    <p className="help is-danger">
                      {this.state.errors.description}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="content">
                  Contenido
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <Editor
                      editorState={content}
                      wrapperClassName="demo-wrapper"
                      editorClassName="box"
                      toolbarClassName=""
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: false },
                        },
                      }}
                    />
                  </div>
                  {this.state.errors.content && (
                    <p className="help is-danger">
                      {this.state.errors.content}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="field">
              <div className="control buttons is-centered">
                <input
                  className="button is-success is-fullwidth"
                  type="submit"
                  value={this.props.edit ? 'Editar' : 'Crear'}
                  style={{ maxWidth: '100px' }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CreateOrEditPublication.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  publications: PropTypes.object,
  getPublication: PropTypes.func.isRequired,
  createPublication: PropTypes.func.isRequired,
  updatePublication: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  publications: state.publications,
});

export default connect(mapStateToProps, {
  getPublication,
  createPublication,
  updatePublication,
})(withRouter(CreateOrEditPublication));
