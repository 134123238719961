import axios from 'axios';
import {
  FETCHING_POSTS,
  FETCHING_POST,
  GET_POSTS,
  GET_ERRORS,
  GET_POST,
  GET_POST_RESOURCES,
  GET_POST_RESOURCE,
  CREATE_POST,
  UPDATE_POST,
  DELETE_POST,
  FETCHING_POST_RESOURCES,
  DELETE_POST_RESOURCE,
} from './types';
import { checkIfSessionExpired } from './authActions';
import { createFileAnchor } from '../utils/utils';

const apiURL = process.env.REACT_APP_API_URL || '';

export const getPosts = () => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setPostsLoading());
  axios
    .get(`${apiURL}/api/posts?list=true`)
    .then((posts) => dispatch({ type: GET_POSTS, payload: posts.data }))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const addFile = (id, fileData) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/posts/${id}/resources`, fileData)
    .then((res) => {
      dispatch(getResources(id));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const deleteResource = (postId, resourceId) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .delete(`${apiURL}/api/posts/${postId}/resources/${resourceId}`)
    .then((resource) => {
      dispatch({ type: DELETE_POST_RESOURCE });
      dispatch(getResources(postId));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getResource = (postId, resourceId, download) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios({
    url: `${apiURL}/api/posts/${postId}/resources/${resourceId}`,
    method: 'GET',
  })
    .then(({ data: resource }) => {
      const blob = new Blob([new Uint8Array(resource.data.data)], {
        type: resource.contentType,
      });
      createFileAnchor(
        download,
        resource.filename,
        download
          ? URL.createObjectURL(blob)
          : `${apiURL}/${encodeURI(resource.path)}`,
      );

      dispatch({ type: GET_POST_RESOURCE, payload: blob });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getResources = (id) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setResourcesLoading());
  axios
    .get(`${apiURL}/api/posts/${id}/resources`)
    .then((resources) => {
      dispatch({ type: GET_POST_RESOURCES, payload: resources.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getPost = (id) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setPostLoading());
  axios
    .get(`${apiURL}/api/posts/${id}`)
    .then((post) => dispatch({ type: GET_POST, payload: post.data }))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const createPost = (postData, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/posts`, postData)
    .then((post) => {
      history.push(`/posts/${post.data._id}`);
      dispatch({ type: CREATE_POST, payload: post.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const updatePost = (id, postData, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/posts/${id}`, postData)
    .then((post) => {
      history.push(`/posts/${post.data._id}`);
      dispatch({ type: UPDATE_POST, payload: post.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const deletePost = (id, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .delete(`${apiURL}/api/posts/${id}`)
    .then((post) => {
      history.push('/posts');
      dispatch({ type: DELETE_POST });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const setPostsLoading = () => {
  return { type: FETCHING_POSTS };
};
export const setPostLoading = () => {
  return { type: FETCHING_POST };
};
export const setResourcesLoading = () => {
  return { type: FETCHING_POST_RESOURCES };
};
