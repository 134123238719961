import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {
  render() {
    const { closeModal, modalState, title, children, close } = this.props;
    if (!modalState) return null;
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={closeModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button className="delete" onClick={closeModal} />
          </header>

          <section className="modal-card-body">{children}</section>

          <footer className="modal-card-foot">
            <span className="button" onClick={closeModal}>
              {close}
            </span>
          </footer>
        </div>
        <button className="modal-close is-large" aria-label="close" />
      </div>
    );
  }
}

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalState: PropTypes.bool.isRequired,
  title: PropTypes.string,
  close: PropTypes.string,
};

export default Modal;
