import {
  GET_ABOUT,
  UPDATE_ABOUT,
  FETCHING_ABOUT,
  IN_ABOUT
} from "../actions/types";

const initialState = {
  about: {},
  fetching: false,
  inAbout: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_ABOUT:
      return { ...state, fetching: true };
    case GET_ABOUT:
      return { ...state, about: action.payload, fetching: false };
    case UPDATE_ABOUT:
      return { ...state, about: action.payload, fetching: false };
    case IN_ABOUT:
      return { ...state, inAbout: action.payload };
    default:
      return state;
  }
}
