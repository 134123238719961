import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Login from '../common/Login';

import { logoutUser } from '../../actions/authActions';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { isActive: false, loginModalState: false, inAbout: false };

    this.toggleNav = this.toggleNav.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleNav() {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  logout() {
    this.props.logoutUser();
  }

  componentWillReceiveProps(nextProps) {
    const { about } = nextProps;
    if (about) this.setState({ inAbout: about.inAbout });
  }

  toggleLoginModal() {
    if (this.props.auth.isAuthenticated) return this.props.logoutUser();
    this.setState((prevState) => {
      const newState = !prevState.loginModalState;
      return { loginModalState: newState };
    });
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <nav
        className="navbar is-dark"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="http://www.casahachiman.com">
            <strong> CasaHachiman </strong>
          </a>

          <span
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            onClick={this.toggleNav}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </span>
        </div>

        <div
          className={
            this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'
          }
        >
          <div className="navbar-start">
            <Link className="navbar-item" to="/" onClick={this.toggleNav}>
              Inicio
            </Link>
            <Link className="navbar-item" to="/cursos" onClick={this.toggleNav}>
              Cursos
            </Link>
            <Link
              className="navbar-item"
              to="/publicaciones"
              onClick={this.toggleNav}
            >
              Publicaciones
            </Link>
            <Link className="navbar-item" to="/posts" onClick={this.toggleNav}>
              Posts
            </Link>
            <Link
              className="navbar-item"
              to="/enlaces"
              onClick={this.toggleNav}
            >
              Enlaces
            </Link>
            <Link
              className="navbar-item"
              to="/acerca-de"
              onClick={this.toggleNav}
            >
              Acerca de
            </Link>
          </div>
          {this.state.inAbout && (
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <span
                    className={
                      isAuthenticated ? 'button is-danger' : 'button is-light'
                    }
                    onClick={this.toggleLoginModal}
                  >
                    {!isAuthenticated && <p>Entrar</p>}
                    {isAuthenticated && <p>Salir</p>}
                  </span>
                </div>
                <Login
                  closeModal={this.toggleLoginModal}
                  modalState={this.state.loginModalState}
                />
              </div>
            </div>
          )}
        </div>
      </nav>
    );
  }
}

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  about: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth, about: state.about });

export default connect(mapStateToProps, { logoutUser })(Header);
