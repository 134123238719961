import axios from 'axios';
import {
  CREATE_COURSE,
  DELETE_COURSE,
  FETCHING_COURSE,
  FETCHING_COURSES,
  GET_COURSE,
  GET_COURSES,
  UPDATE_COURSE,
  GET_ERRORS,
  FETCHING_RESOURCES,
  GET_RESOURCES,
  DELETE_RESOURCE,
  GET_RESOURCE,
} from './types';
import { checkIfSessionExpired } from './authActions';
import { createFileAnchor } from '../utils/utils';

const apiURL = process.env.REACT_APP_API_URL || '';

export const getCourses = () => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setCoursesLoading());
  axios
    .get(`${apiURL}/api/courses?list=true`)
    .then((courses) => dispatch({ type: GET_COURSES, payload: courses.data }))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getCourse = (id) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setCourseLoading());
  axios
    .get(`${apiURL}/api/courses/${id}`)
    .then((course) => dispatch({ type: GET_COURSE, payload: course.data }))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const createCourse = (courseData, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/courses`, courseData)
    .then((course) => {
      history.push(`/cursos/${course.data._id}`);
      dispatch({ type: CREATE_COURSE, payload: course.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const updateCourse = (id, courseData, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/courses/${id}`, courseData)
    .then((course) => {
      history.push(`/cursos/${course.data._id}`);
      dispatch({ type: UPDATE_COURSE, payload: course.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const deleteCourse = (id, history) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .delete(`${apiURL}/api/courses/${id}`)
    .then((course) => {
      history.push('/cursos');
      dispatch({ type: DELETE_COURSE });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const addFile = (id, fileData) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .post(`${apiURL}/api/courses/${id}/resources`, fileData)
    .then((res) => {
      dispatch(getResources(id));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getResources = (id) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  dispatch(setResourcesLoading());
  axios
    .get(`${apiURL}/api/courses/${id}/resources`)
    .then((resources) => {
      dispatch({ type: GET_RESOURCES, payload: resources.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const deleteResource = (courseId, resourceId) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios
    .delete(`${apiURL}/api/courses/${courseId}/resources/${resourceId}`)
    .then((resource) => {
      dispatch({ type: DELETE_RESOURCE });
      dispatch(getResources(courseId));
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const getResource = (courseId, resourceId, download) => (dispatch) => {
  dispatch(checkIfSessionExpired());
  axios({
    url: `${apiURL}/api/courses/${courseId}/resources/${resourceId}`,
    method: 'GET',
  })
    .then(({ data: resource }) => {
      const blob = new Blob([new Uint8Array(resource.data.data)], {
        type: resource.contentType,
      });
      createFileAnchor(
        download,
        resource.filename,
        download
          ? URL.createObjectURL(blob)
          : `${apiURL}/${encodeURI(resource.path)}`,
      );
      dispatch({ type: GET_RESOURCE, payload: blob });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const setCoursesLoading = () => {
  return { type: FETCHING_COURSES };
};

export const setCourseLoading = () => {
  return { type: FETCHING_COURSE };
};

export const setResourcesLoading = () => {
  return { type: FETCHING_RESOURCES };
};
