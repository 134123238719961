import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Hero from '../common/Hero';
import Delete from '../common/Delete';

import { getPosts, deletePost } from '../../actions/postsActions';
import isEmpty from '../../validation/is-empty';

class AllPosts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      deleteModalState: false,
      id: null,
      errors: {},
    };

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  componentDidMount() {
    this.props.getPosts();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors)
      this.setState({
        errors: nextProps.errors,
        posts: nextProps.posts.posts,
      });
    else this.state({ posts: nextProps.posts.posts });
  }

  toggleDeleteModal() {
    this.setState((prevState) => {
      return { deleteModalState: !prevState.deleteModalState };
    });
  }

  doDelete(id) {
    this.props.deletePost(id, this.props.history);
    this.props.getPosts();
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { posts, fetching } = this.props.posts;

    const foot = (
      <div>
        {isAuthenticated && (
          <Link to="/posts/crear">
            <button className="button is-success">Agregar Post</button>
          </Link>
        )}
      </div>
    );

    return (
      <div>
        <Hero title="Posts" color="is-link" foot={foot} />
        <section className="container">
          {fetching && (
            <button className="button is-loading is-fullwidth is-medium" />
          )}
          {!fetching && (
            <div>
              {isEmpty(posts) && <p className="subtitle">No hay posts</p>}
              {!isEmpty(posts) && (
                <section>
                  {posts.map((value, index) => {
                    return (
                      <div key={index}>
                        <article
                          className="box"
                          style={{ marginBottom: '15px', textAlign: 'left' }}
                        >
                          <div
                            className={classnames('', {
                              columns: isAuthenticated,
                            })}
                          >
                            <div
                              className={classnames('', {
                                'column is-10': isAuthenticated,
                              })}
                            >
                              <Link to={`/posts/${value._id}`}>
                                <p className="title">{value.title}</p>
                                <p className="subtitle">{value.description}</p>
                              </Link>
                            </div>
                            {isAuthenticated && (
                              <div
                                className="field is-grouped column is-2"
                                style={{ justifyContent: 'flex-end' }}
                              >
                                <p className="control">
                                  <Link to={`/posts/editar/${value._id}`}>
                                    <button className="button is-warning">
                                      Editar
                                    </button>
                                  </Link>
                                </p>
                                <p className="control">
                                  <span
                                    className="button is-danger"
                                    onClick={() => {
                                      this.setState({
                                        id: posts[index]._id,
                                      });
                                      this.toggleDeleteModal();
                                    }}
                                  >
                                    Eliminar
                                  </span>
                                </p>
                                <Delete
                                  closeModal={this.toggleDeleteModal}
                                  modalState={this.state.deleteModalState}
                                  doDelete={this.doDelete}
                                  id={this.state.id}
                                  deleteWhat={'el post'}
                                />
                              </div>
                            )}
                          </div>
                        </article>
                      </div>
                    );
                  })}
                </section>
              )}
            </div>
          )}
        </section>
      </div>
    );
  }
}

AllPosts.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  posts: PropTypes.object.isRequired,
  getPosts: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  posts: state.posts,
});

export default connect(mapStateToProps, { getPosts, deletePost })(AllPosts);
