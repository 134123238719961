import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Hero from '../common/Hero';
import CreateField from '../common/CreateField';

import { getPost, createPost, updatePost } from '../../actions/postsActions';
import { uploadImageCallBack } from '../../utils/utils';

class CreateOrEditPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      description: '',
      content: EditorState.createEmpty(),
      commentaries: [],
      id: null,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) this.props.history.push('/posts');
    if (this.props.edit) this.props.getPost(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.auth.isAuthenticated) this.props.history.push('/posts');

    let errors = {};
    if (nextProps.errors) errors = nextProps.errors;

    if (this.props.edit && nextProps.posts.post) {
      const {
        _id: id,
        title,
        description,
        content,
        commentaries,
      } = nextProps.posts.post;

      let editorState;
      if (content) {
        const contentBlock = htmlToDraft(content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          editorState = EditorState.createWithContent(contentState);
        }
      } else {
        editorState = EditorState.createEmpty();
      }

      this.setState({
        errors,
        id,
        title,
        description,
        content: editorState,
        commentaries,
      });
    } else {
      this.setState({ errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      content: editorState,
    });
  };

  onSubmit(e) {
    e.preventDefault();
    const { id, title, description, commentaries } = this.state;
    let { content } = this.state;
    content = draftToHtml(convertToRaw(content.getCurrentContent()));
    const post = { title, description, content, commentaries };
    if (this.props.edit) {
      this.props.updatePost(id, post, this.props.history);
    } else this.props.createPost(post, this.props.history);
  }

  render() {
    const { title, description, content, errors } = this.state;
    return (
      <div>
        <Hero
          title={this.props.edit ? 'Editar Post' : 'Crear Post'}
          color="is-link"
        />
        <div className="container has-text-centered box">
          <form onSubmit={this.onSubmit}>
            <CreateField
              htmlFor="title"
              label="Titulo"
              name="title"
              placeholder="Titulo"
              value={title}
              onChange={this.onChange}
              error={errors.title}
            />
            <CreateField
              htmlFor="description"
              label="Descripción"
              name="description"
              placeholder="Descripción"
              value={description}
              onChange={this.onChange}
              error={errors.description}
            />
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label" htmlFor="content">
                  Contenido
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <Editor
                      editorState={content}
                      wrapperClassName="demo-wrapper"
                      editorClassName="box"
                      toolbarClassName=""
                      onEditorStateChange={this.onEditorStateChange}
                      toolbar={{
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: false },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="field">
              <div className="control buttons is-centered">
                <input
                  className="button is-success is-fullwidth"
                  type="submit"
                  value={this.props.edit ? 'Editar' : 'Crear'}
                  style={{ maxWidth: '100px' }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CreateOrEditPost.propType = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  posts: PropTypes.object,
  getPost: PropTypes.func.isRequired,
  createPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  posts: state.posts,
});

export default connect(mapStateToProps, { getPost, createPost, updatePost })(
  withRouter(CreateOrEditPost),
);
