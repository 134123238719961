import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import AllPosts from './AllPosts';
import Post from './Post';
import CreateOrEditPost from './CreateOrEditPost';
import Resources from './Resources';
import NotFound from '../NotFound';

class Posts extends Component {
  render() {
    return (
      <div>
        <div>
          <Switch>
            <Route exact path="/posts" component={AllPosts} />
            <Route
              exact
              path="/posts/crear"
              render={(props) => <CreateOrEditPost edit={false} />}
            />
            <Route
              exact
              path="/posts/editar/:id"
              render={(props) => <CreateOrEditPost edit={true} />}
            />
            <Route exact path="/posts/:id/recursos" component={Resources} />
            <Route path="/posts/:id" component={Post} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Posts;
