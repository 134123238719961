import React from 'react';
import PropTypes from 'prop-types';

const SocialMediaIcon = ({
  socialNetwork: { user, baseUrl, iconClassName },
}) => (
  <a
    className="icon is-medium"
    href={`${baseUrl}${user}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className={`fa-lg ${iconClassName}`} />
  </a>
);

SocialMediaIcon.propTypes = {
  socialNetwork: PropTypes.object.isRequired,
};

export default SocialMediaIcon;
