import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer
        className="footer has-background-dark has-text-white is-small"
        style={{
          flex: 'none',
          padding: '10px 0',
        }}
      >
        <div className="content has-text-centered">
          <p>Página web creada por Renán Zelaya.</p>
          <p>
            Fuente del{' '}
            <a href="http://www.irondragondesign.com/wp-content/uploads/2017/11/Mitsudomoe-500x500.png">
              favicon
            </a>
          </p>
          <p>
            <a className="icon" href="https://www.github.com/renanz">
              <i className="fab fa-github" />
            </a>
            <a
              className="icon"
              href="https://www.linkedin.com/in/ren%C3%A1n-alberto-zelaya-4a067bb3/"
            >
              <i className="fab fa-linkedin" />
            </a>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
