import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AllCourses from './AllCourses';
import Course from './Course';
import CreateOrEditCourse from './CreateOrEditCourse';
import Resources from './Resources';
import NotFound from '../NotFound';

class Courses extends Component {
  render() {
    return (
      <div>
        <div>
          <Switch>
            <Route exact path="/cursos" component={AllCourses} />
            <Route
              exact
              path="/cursos/crear"
              render={() => <CreateOrEditCourse edit={false} />}
            />
            <Route
              exact
              path="/cursos/editar/:id"
              render={() => <CreateOrEditCourse edit={true} />}
            />
            <Route exact path="/cursos/:id/recursos" component={Resources} />
            <Route path="/cursos/:id" component={Course} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Courses;
