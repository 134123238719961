import axios from 'axios';
import {
  GET_ABOUT,
  UPDATE_ABOUT,
  FETCHING_ABOUT,
  GET_ERRORS,
  IN_ABOUT,
} from './types';

const apiURL = process.env.REACT_APP_API_URL || '';

export const getAbout = () => (dispatch) => {
  dispatch(setAboutLoading());
  axios
    .get(`${apiURL}/api/about/${process.env.REACT_APP_ABOUT_ID}`)
    .then((about) => dispatch({ type: GET_ABOUT, payload: about.data }))
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const updateAbout = (aboutData) => (dispatch) => {
  axios
    .post(`${apiURL}/api/about/${process.env.REACT_APP_ABOUT_ID}`, aboutData)
    .then((about) => {
      dispatch({ type: UPDATE_ABOUT, payload: about.data });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: GET_ERRORS, payload: err.response.data });
      } else {
        console.log(err);
      }
    });
};

export const setAboutLoading = () => {
  return { type: FETCHING_ABOUT };
};

export const inAbout = (value) => {
  return { type: IN_ABOUT, payload: value };
};
