import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';

import Hero from '../common/Hero';

import { getPublication } from '../../actions/publicationsActions';
import { Link } from 'react-router-dom';

class Publication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      publication: {},
    };
  }

  componentDidMount() {
    this.props.getPublication(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ publication: nextProps.publications.publication });
  }

  render() {
    const { fetching, publication } = this.props.publications;

    if (publication === null || fetching) {
      return (
        <div>
          <Hero color="is-link" />
          <section className="container">
            {fetching && (
              <button className="button is-loading is-fullwidth is-medium" />
            )}
          </section>
        </div>
      );
    } else {
      const { title, content, type, _id, resourcesSize } = publication;
      return (
        <div>
          <Hero title={title} subtitle={type} color="is-link" />
          <section className="container">
            <div className="" style={{ textAlign: 'left' }}>
              {renderHTML(content || '')}
            </div>
            {(resourcesSize > 0 || this.props.auth.isAuthenticated) && (
              <Link to={`/publicaciones/${_id}/recursos`}>
                <article className="">
                  <p className="title">Recursos</p>
                </article>
              </Link>
            )}
          </section>
        </div>
      );
    }
  }
}

Publication.propTypes = {
  publications: PropTypes.object.isRequired,
  getPublication: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  publications: state.publications,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPublication })(Publication);
