import React, { Component } from 'react';

class Hero extends Component {
  render() {
    return (
      <>
        <section
          className={`hero ${this.props.color}`}
          style={{ textAlign: 'left' }}
        >
          <div className="hero-body">
            <div className="container">
              <h1 className="title">{this.props.title}</h1>
              <h1 className="subtitle">{this.props.subtitle}</h1>
            </div>
          </div>
          <div className="hero-foot">
            <div className="container">{this.props.foot}</div>
          </div>
        </section>
        <br />
      </>
    );
  }
}

export default Hero;
