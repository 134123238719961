import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import Hero from './common/Hero';
import { getAbout } from '../actions/aboutActions';
import SocialMediaIcon from './About/SocialMediaIcon';

class Home extends React.PureComponent {
  pageLinks = [
    {
      title: 'Cursos',
      link: '/cursos',
    },
    {
      title: 'Publicaciones',
      link: '/publicaciones',
    },
    {
      title: 'Posts',
      link: '/posts',
    },
    {
      title: 'Enlaces',
      link: '/enlaces',
    },
  ];
  componentDidMount() {
    this.props.getAbout();
  }
  render() {
    const {
      about: {
        about: { socialNetworks, pageDescription, secondaryPageDescription },
      },
    } = this.props;
    return (
      <>
        <Hero title="Bienvenido" color="is-link" />
        <div className="container is-fluid">
          <div
            className="notification"
            style={{
              backgroundColor: 'white',
              paddingBottom: '0rem',
              paddingTop: '0rem',
            }}
          >
            {renderHTML(pageDescription || '')}
          </div>
          <div
            className="tile is-ancestor"
            style={{ marginBottom: '0px !important' }}
          >
            <div className="tile is-vertical is-4" />
            <div className="tile is-vertical is-4">
              <div className="tile">
                <div className="tile is-parent is-vertical">
                  {this.pageLinks.map(({ title, subtitle, link }) => (
                    <Link
                      id="home-tiles"
                      to={link}
                      key={title}
                      className="tile is-child box"
                      style={{
                        backgroundColor: '#4a4a4a',
                        marginBottom: '10px !important',
                      }}
                    >
                      <p className="title" style={{ color: 'white' }}>
                        {title}
                      </p>
                      <p className="subtitle">{subtitle || ''}</p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="notification" style={{ backgroundColor: 'white' }}>
            {renderHTML(secondaryPageDescription || '')}
            {socialNetworks &&
              socialNetworks.map((socialNetwork) => (
                <SocialMediaIcon
                  key={socialNetwork.user}
                  socialNetwork={socialNetwork}
                />
              ))}
          </div>
        </div>
      </>
    );
  }
}

Home.propTypes = {
  about: PropTypes.object.isRequired,
  getAbout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  about: state.about,
});

export default connect(mapStateToProps, { getAbout })(Home);
