import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';

import Hero from '../common/Hero';

import { getPost, updatePost } from '../../actions/postsActions';
import isEmpty from '../../validation/is-empty';

class Post extends Component {
  constructor(props) {
    super(props);

    this.state = {
      post: {
        title: null,
        description: null,
        content: null,
        commentaries: [],
        createdAt: null,
        updatedAt: null,
      },
      text: '',
      postedBy: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }

  componentDidMount() {
    this.props.getPost(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ post: nextProps.posts.post });
  }

  deleteComment(pos) {
    const { post } = this.state;
    const { title, description, content } = post;
    let { commentaries } = post;
    commentaries.splice(pos, 1);
    this.props.updatePost(
      this.props.match.params.id,
      { title, description, content, commentaries },
      this.props.history,
    );
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { text, postedBy, post } = this.state;
    const { title, description, content } = post;
    let { commentaries } = post;
    commentaries.push({ text, postedBy });
    this.props.updatePost(
      this.props.match.params.id,
      { title, description, content, commentaries },
      this.props.history,
    );
    this.setState({ text: '', postedBy: '' });
  }

  render() {
    const { fetching, post } = this.props.posts;

    if (post === null || fetching) {
      return (
        <div>
          <Hero color="is-link" />
          <section className="container">
            {fetching && (
              <button className="button is-loading is-fullwidth is-medium" />
            )}
          </section>
        </div>
      );
    } else {
      const {
        _id: id,
        title,
        description,
        content,
        commentaries,
        createdAt,
        resourcesSize,
      } = post;
      const { isAuthenticated } = this.props.auth;
      const creationDate = new Date(createdAt).toLocaleString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      return (
        <>
          <Hero title={title} subtitle={creationDate} color="is-link" />
          <section className="container">
            <article className="box" style={{ textAlign: 'left' }}>
              <p className="title">Descripción</p>
              <span className="subtitle">{description}</span>
            </article>
            <article className="box">
              <div
                className="subtitle"
                style={{ whiteSpace: 'pre-line', display: 'inline-block' }}
              >
                {renderHTML(content || '')}
              </div>
            </article>
            {(resourcesSize > 0 || this.props.auth.isAuthenticated) && (
              <Link to={`/posts/${id}/recursos`}>
                <article className="box">
                  <p className="title">Recursos</p>
                </article>
              </Link>
            )}
            <br />
            <article className="box">
              <p className="title" style={{ textAlign: 'left' }}>
                Comentarios
              </p>
              {isEmpty(commentaries) && (
                <p className="subtitle" style={{ textAlign: 'left' }}>
                  No hay Comentarios
                </p>
              )}
              {commentaries.map((value, index) => {
                return (
                  <article key={index} className="box">
                    <div className="columns">
                      <div className="column is-9">
                        <p className="title is-4">{value.postedBy}</p>
                        <p className="subtitle is-6">{value.text}</p>
                      </div>
                      <div className="column is-3">
                        {isAuthenticated && (
                          <div
                            className="field is-grouped"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <div className="control">
                              <button
                                className="button is-danger"
                                onClick={() => {
                                  this.deleteComment(index);
                                }}
                              >
                                Eliminar
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </article>
                );
              })}
              <form onSubmit={this.onSubmit}>
                <article className="media">
                  <div className="media-content">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          placeholder="Nombre"
                          name="postedBy"
                          required={true}
                          value={this.state.postedBy}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Agregue un comentario"
                          name="text"
                          required={true}
                          value={this.state.text}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <nav className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <input
                            className="button is-info"
                            type="submit"
                            value="Agregar"
                          />
                        </div>
                      </div>
                    </nav>
                  </div>
                </article>
              </form>
            </article>
          </section>
        </>
      );
    }
  }
}

Post.propTypes = {
  auth: PropTypes.object.isRequired,
  posts: PropTypes.object.isRequired,
  getPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  posts: state.posts,
});

export default connect(mapStateToProps, { getPost, updatePost })(Post);
